import React from "react";
import "./Wheel.scss";
import Prize1 from "./prize-images/Prize1";
import Prize2 from "./prize-images/Prize2";
import Prize3 from "./prize-images/Prize3";
import CustomImage from "../CustomImage/CustomImage";

const WheelWithCss = ({ isSpin, wheelData }) => {
  let width = window.innerWidth - 40;
  if (width > 440) width = 440;
  return (
    <div className={`wheel_container  ${isSpin && " spin"} `}>
      <img src={require("./WheelBorder.png")} alt="" className="border_ring" />
      <img src={require("./pin.png")} alt="" className="wheel_pin" />
      <div
        style={{ width: `${width}px`, height: `${width}px` }}
        className="wheel"
      >
        {wheelData?.map((each, i) => (
          <div
            style={{
              background: colorGenerate(i),
              transform: `rotate( ${(360 / wheelData?.length) * (i + 1)}deg )`,
              clipPath: `polygon(0 0, ${clipPathGenerate(
                wheelData?.length
              )}% 0, 100% 100%, 0 ${clipPathGenerate(wheelData?.length)}%)`,
            }}
            className="segment"
          >
            <CustomImage src={each?.image} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default WheelWithCss;

const colorGenerate = (i) => {
  if (i % 2 == 0) {
    return "radial-gradient(59.32% 59.32% at 49.98% 49.98%, #5C573A 0%, #580043 84.95%, #5C573A 100%)";
  } else
    return "radial-gradient(134.84% 117.23% at 0.16% 49.17%, #F5D919 0%, #F16922 84.95%, #F5D919 100%)";
};
const clipPathGenerate = (segmentsCount) => {
  let aroundDegreeWaste = segmentsCount * 90 - 360;
  return 100 - aroundDegreeWaste / (segmentsCount + 1);
};

// const wheelData = [
//   {
//     id: 1,
//     title: "A",
//     img: <Prize1 />,
//   },
//   {
//     id: 1,
//     title: "A",
//     img: <Prize2 />,
//   },
//   {
//     id: 1,
//     title: "A",
//     img: <Prize3 />,
//   },
//   {
//     id: 1,
//     title: "A",
//     img: <Prize1 />,
//   },
//   {
//     id: 1,
//     title: "A",
//     img: <Prize2 />,
//   },
//   {
//     id: 1,
//     title: "A",
//     img: <Prize3 />,
//   },
// ];

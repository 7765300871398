import React from "react";
import Modal from "../../../Components/Modal/Modal";
import Button from "../../../Components/Button/Button";
import GuestIcon from "./GuestIcon";
import "./Popup.scss";
import { useNavigate } from "react-router-dom";

const GuestConfirmationPopup = ({ closer }) => {
  const navigate = useNavigate();
  const continueHandler = () => {
    navigate("/");
  };
  return (
    <Modal show>
      <div className="reset_pass_popup">
        <div className="icon">
          <GuestIcon />
        </div>
        <p className="main_title">Continue as Guest?</p>
        <p className="description">
          Guest’s games and winnings will be kept for 7 days. Register to save
          your progress.
        </p>
        <div className="bottom">
          <p onClick={closer} className="close_btn pointer">
            Close
          </p>
          <Button onClick={continueHandler} type="small" title="Continue" />
        </div>
      </div>
    </Modal>
  );
};

export default GuestConfirmationPopup;

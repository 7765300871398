import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UseApiError from "../../Hooks/Common/UseApiError";
import Loader from "../../Components/Loader/Loader";
import WheelWithCss from "../../Components/Wheel/WheelWithCss";
import Button from "../../Components/Button/Button";
import NavBar from "../../Components/NavBar/NavBar";
import { invitedLuckyDraws } from "./api-data/data";
import LuckyDrawCard from "./LuckyDrawCard/LuckyDrawCard";
import "./HomePage.scss";
import {
  fetchAllLuckydraws,
  selectHomepageStore,
} from "../../redux/homePageSlice";

export default function HomePage() {
  // UseApiError(error, clearScrollContentFetchError);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllLuckydraws());
  }, []);

  const { isLoading, data } = useSelector(selectHomepageStore);
  console.log(data, "some data");

  return (
    <>
      <div className="page">
        <Loader loading={isLoading} />
        <p className="section_title">Invited Lucky Draws</p>
        {data?.invitations?.map((each) => (
          <LuckyDrawCard
            banner_img={each?.image}
            brand_img={each?.avatar}
            created_at={each?.created_at || ""}
            description={each?.description || ""}
            id={each?.event_id}
            spins={each?.spins || 0}
            title={each?.name || ""}
            type={each?.type || ""}
            random_number={each?.random_number || ""}
          />
        ))}
      </div>
    </>
  );
}

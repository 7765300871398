import React from "react";
import "./LuckyDrawCard.scss";
import Button from "../../../Components/Button/Button";
import { useNavigate } from "react-router-dom";
import CustomImage from "../../../Components/CustomImage/CustomImage";
import { formatDateFunction2 } from "../../../utils/utils";

const LuckyDrawCard = ({
  banner_img,
  brand_img,
  title,
  description,
  created_at,
  spins,
  type,
  id,
  random_number,
}) => {
  const navigate = useNavigate();
  const spinClickHandler = () => {
    navigate(`/luckydraw?random_number=${random_number}`);
  };
  return (
    <div className="luckydraw_card">
      <div className="banner_img">
        <CustomImage src={banner_img} alt="" />
      </div>
      <div className="flex align-center">
        <div className="brand_img">
          <CustomImage src={brand_img} alt="" />
        </div>
        <p className="title">{title}</p>
      </div>
      <p className="description">{description}</p>
      <div className="flex justify-between align-center">
        <p className="created_at">{formatDateFunction2(created_at)}</p>
        <Button
          className="luckydraw_small_btn"
          type="small"
          title={`${spins} ${type == "spinner" ? "Spin" : "Scratch"}`}
          onClick={spinClickHandler}
        />
      </div>
    </div>
  );
};

export default LuckyDrawCard;

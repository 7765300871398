import React from "react";
import Background from "./Background";
import ImageSquezeCont from "./ImageSquezeCont";
import "./WinningPrizeCard.scss";
import Background2 from "./Background2";
import ImageSqueezeCont2 from "./ImageSqueezeCont2";
import Background4 from "./Background4";
import Background3 from "./Background3";
import ImageSqueezeCont3 from "./ImageSqueezeCont3";
import ImageSqueezeCont4 from "./ImageSqueezeCont4";
import ClaimIcon from "../../assets/svgs/ClaimIcon";

const WinningPrizeCard = ({
  id,
  image,
  title,
  possibility,
  reward_amount,
  onClick = () => {},
  claimClickHandler = () => {},
  showClaim,
  index,
  isHidePossibility = false,
  data,
  isShowStatus,
  status,
}) => {
  return (
    <div
      onClick={() => onClick(id)}
      className={`winning_prize winning-prize-${index} a${
        index != 0 && (index + 1) / 4
      }`}
    >
      {isShowStatus && <p className={`status_pill ${status}`}>{status}</p>}
      {index % 4 == 0 && <Background className="background" />}
      {index % 4 == 1 && <Background2 className="background" />}
      {index % 4 == 2 && <Background3 className="background" />}
      {index % 4 == 3 && <Background4 className="background" />}
      <div className="content">
        <div className="image_cont">
          {index % 4 == 0 && (
            <ImageSquezeCont id={id} image={image} className="image_bg" />
          )}
          {index % 4 == 1 && (
            <ImageSqueezeCont2 id={id} image={image} className="image_bg" />
          )}
          {index % 4 == 2 && (
            <ImageSqueezeCont3 id={id} image={image} className="image_bg" />
          )}
          {index % 4 == 3 && (
            <ImageSqueezeCont4 id={id} image={image} className="image_bg" />
          )}
        </div>
        <div className="bottom">
          <p className="title">{title}</p>
          {!isHidePossibility && (
            <p className="possiblity">{possibility}% Possibility</p>
          )}
          <div className="flex align-center justify-between">
            <p className="amount">INR {reward_amount}</p>
            {showClaim && (
              <div
                onClick={() => claimClickHandler(id, data)}
                className="claim_icon"
              >
                <ClaimIcon />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinningPrizeCard;

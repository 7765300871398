export const providerWinnings = [
    {
        prizes_count: 2,
        provider_logo: require('./images/provider_logo_1.png'),
        id: 1
    },
    {
        prizes_count: 3,
        provider_logo: require('./images/provider_logo_2.png'),
        id: 2
    },
]
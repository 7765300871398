import React from "react";

const ImageSqueezeCont2 = (props) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 150 97"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M5.05481 20.3354C5.50429 13.5183 5.72903 10.1098 7.92207 7.96915C10.1151 5.82854 13.5281 5.68633 20.3541 5.40192L133.334 0.694458C141.162 0.3683 145.076 0.205221 147.538 2.56691C150 4.92861 150 8.84593 150 16.6806V75.6272C150 82.8984 150 86.534 147.769 88.8563C145.538 91.1785 141.905 91.3238 134.639 91.6144L17.7752 96.289C9.47573 96.621 5.32599 96.787 2.83834 94.2341C0.350684 91.6813 0.623918 87.5372 1.17039 79.2491L5.05481 20.3354Z"
        fill={`url(#pattern${props?.id})`}
      />
      <defs>
        <pattern
          height="1"
          id={`pattern${props?.id}`}
          width="1"
          patternContentUnits="objectBoundingBox"
        >
          <image
            xlinkHref={props?.image}
            height="160"
            width="250"
            transform="matrix(0.00449074 0 0 0.00694444 -0.0748148 0)"
            preserveAspectRatio="xMidYMid slice"
          />
        </pattern>
      </defs>
    </svg>
  );
};

export default ImageSqueezeCont2;

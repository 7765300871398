
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import { getUrlParam } from '../utils/utils';

const initialState = {
    data: null,
    isLoading: false,
    detail: null,
    error: null
}



export const fetchWinHistory = createAsyncThunk('fetchwindHistory', (payload, { rejectWithValue }) => {
    let route = '/winhistoryuser'
    let random_number = getUrlParam('random_number');
    const LukyX3UserDetails = localStorage.getItem("LukyX3UserDetails");
    if (random_number && !LukyX3UserDetails) route = `/winhistory?random_number=${random_number}`
    return axios.get(route).then(res => res.data)
});


export const winnerHistorySlice = createSlice({
    name: 'dashboard',
    initialState,
    extraReducers: (builder) => {
        //////////// FETCH Winner History ////////////////
        builder.addCase(fetchWinHistory.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchWinHistory.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload?.data;
        })
        builder.addCase(fetchWinHistory.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message;
        })
    }
})

export const selectWinHistory = (store) => store.winnerHistory.data?.prizes;

export const selectWinnerHistoryByProvider = (store, provider_id) => {
    let winnerHistorydata = store.winnerHistory?.data?.prizes;
    let provider_info = winnerHistorydata?.find(each => each.provider?.id == provider_id)
    return provider_info;
}


export default winnerHistorySlice.reducer;




